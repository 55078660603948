import * as React from 'react'
import '../assets/css/index.css'
import Navigation from '../components/Navigation'; 
import Footer from '../components/Footer'
import { Container, Modal, Col, Row } from "react-bootstrap";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

// markup
const IndexPage = () => {
  return (
    <main>
      <Navigation/>
      <GatsbySeo
        title='Contact - Pinches Cue Club & The Venue - Snooker, Pool and Darts | Norwich Snooker and Pool Club | Private Function Room'
        description='Please contact us via telephone to book a table or find out more information'
        />
      <div className='black-section'>
      <Container>
            <Row>
            <h2 className='pt-3 pb-3'>Information</h2>
              <Col lg={6} md={12} sm={12} xs={12} className="black-section ">
                <h3 className='pb-3'>Opening times</h3>
                <p>Monday: 10:00 - 22:00</p>
                <p>Tuesday: 10:00 - 22:00</p>
                <p>Wednesday: 10:00 - 22:00</p>
                <p>Thursday: 10:00 - 22:00</p>
                <p>Friday: 10:00 - 22:00</p>
                <p>Saturday: 10:00 - 22:00</p>
                <p>Sunday: 10:00 - 22:00</p>
                <p>Our closing times do vary, if you need to clarify anything then please call the club to check.</p>
               </Col> 
                <Col lg={6} md={12} sm={12} xs={12} className="black-section ">
                <h2 className='pb-3'>Address</h2>
                <address>
                85 Barker Street,
                <br />
                Norwich,
                <br />
                Norfolk.
                <br />
                NR2 4TN.
              </address>
                <p>Telephone number</p>
                <a href="tel:01603 622427">01603 622427</a>

            </Col>
          </Row>
      </Container>
      <Footer/>
      </div>
    </main>
  )
}

export default IndexPage
